import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface notificationHistory {
  id: number;
  meta: string;
  notification_details: notificationDetails;
  read: boolean;
  read_timestamp: number;
  type: string;
  user: string;
}

interface notificationDetails {
  created_timestamp: number;
  description: string;
  id : number;
  notification_type: string;
  title: string;
}

interface NotificationState {
    notificationsQueue: notificationHistory[];
    currentNotification: notificationHistory | null;

    nonCriticalNotificationQueue: notificationHistory[];
    currentNonCriticalNotification: notificationHistory | null;

    notification_id: number;
    notificationType: string;
    notificationMessage: string;
    notificationTitle: string;
    isNotificationOn: boolean;
    colorProps: {
        background: string;
        border: string;
    }
    notificationHistoryPanel: boolean;
    timestamp: number;

    isNonCriticalNotificationOn: boolean;
    activeSnackbars: notificationHistory[];

    notificationSelected: notificationHistory | null;

}

interface Notification {
  id: number;
  title: string;
  message: string;
  type: string;
  timestamp: number;
}

// interface NotificationState {
//   notificationsQueue: Notification[];
//   currentNotification: Notification | null;
//   isNotificationOn: boolean;
// }

// const initialState: NotificationState = {
//   notificationsQueue: [],
//   currentNotification: null,
//   isNotificationOn: false,
// };


const initialState: NotificationState = {
  notification_id : 0,
  notificationType: '', 
  notificationMessage: '',
  notificationTitle: '',
  isNotificationOn: false,
  colorProps: {
    background: '',
    border: ''
  },
  notificationHistoryPanel: false,
  timestamp: 0,

  notificationsQueue: [],
  currentNotification: null,

  nonCriticalNotificationQueue: [],
  currentNonCriticalNotification: null,
  activeSnackbars: [],

  isNonCriticalNotificationOn: false,
  notificationSelected: null,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {

    // Add a notification to the queue
    addNotification: (state, action: PayloadAction<notificationHistory>) => {
      state.notificationsQueue.push(action.payload);
      if (!state.isNotificationOn && state.notificationsQueue.length > 0) {
          console.log(" adding notification: display next notification");
          state.currentNotification = state.notificationsQueue.shift() || null;
          state.isNotificationOn = true;
          console.log("state.currentNotification", state.currentNotification, state.isNotificationOn, state.notificationsQueue);
      }
    },
    // Display the next notification in the queue
    showNextNotification: (state) => {
      console.log("Before shifting queue:", state.notificationsQueue);
        state.isNotificationOn = false;
        if (state.notificationsQueue.length > 0) {
            state.currentNotification = state.notificationsQueue.shift() || null;
            if (state.currentNotification?.notification_details.notification_type === "job_notification") {
              state.notificationType = "Job Update";
            }
            else{
              state.notificationType = "Robot Update";
            }
            state.isNotificationOn = true;

        } else {
            state.currentNotification = null;
            state.isNotificationOn = false;
        }
    },
      setNotificationOff2: (state) => {
        state.isNotificationOn = false;
        state.currentNotification = null;
      },

      addNonCriticalNotification: (state, action: PayloadAction<notificationHistory>) => {
        state.nonCriticalNotificationQueue.push(action.payload);
        if (!state.isNotificationOn && state.nonCriticalNotificationQueue.length > 0) {
          console.log(" adding notification: display next notification");
            state.currentNonCriticalNotification = state.nonCriticalNotificationQueue.shift() || null;
            state.isNotificationOn = true;
            console.log("state.currentNotification", state.currentNonCriticalNotification, state.isNotificationOn, state.nonCriticalNotificationQueue);
        }
      },

      setNonCriticalNotificationOn: (state) => {
        state.isNonCriticalNotificationOn = true;
      },

      showNextNonCriticalNotification: (state) => {
        console.log("Before shifting queue:", state.nonCriticalNotificationQueue);
        state.isNotificationOn = false;
        if (state.nonCriticalNotificationQueue.length > 0) {
            state.currentNonCriticalNotification = state.nonCriticalNotificationQueue.shift() || null;
            state.isNotificationOn = true;
            console.log("Shifted queue:", state.nonCriticalNotificationQueue);
            console.log("Next notification displayed:", state.currentNonCriticalNotification);
        } else {
            state.currentNonCriticalNotification = null;
            state.isNotificationOn = false;
            console.log("No more notifications left, turning off");
        }
    },
    markNotificationAsRead: (state, action: PayloadAction<string>) => {
      const orderNumber = action.payload;
      state.notificationsQueue = state.notificationsQueue.filter((notification) => JSON.parse(notification.meta).order_number !== orderNumber);

      // If the current notification is the one being read, set it to null
      if (state.currentNotification && JSON.parse(state.currentNotification.meta).order_number === orderNumber) {
        state.currentNotification = null;
        state.isNotificationOn = false;
      }
    },

    markRobotNotificationAsRead: (state, action: PayloadAction<string>) => {
      const robot_id = action.payload;
      state.notificationsQueue = state.notificationsQueue.filter((notification) => JSON.parse(notification.meta).robot_id !== robot_id);

      // If the current notification is the one being read, set it to null
      if (state.currentNotification && JSON.parse(state.currentNotification.meta).robot_id === robot_id) {
        state.currentNotification = null;
        state.isNotificationOn = false;
      }
    },

    addNotifications: (state, action: PayloadAction<notificationHistory[]>) => {
      const uniqueNotifications = action.payload.filter(
        (notification) => !state.notificationsQueue.some((existingNotification) => existingNotification.id === notification.id)
      );
      console.log("uniqueNotifications", uniqueNotifications);
      // Add the unique notifications to the queue
      state.notificationsQueue = uniqueNotifications

      // Display the first notification if none is currently being displayed
      if (!state.isNotificationOn && state.notificationsQueue.length > 0) {
        state.currentNotification = state.notificationsQueue.shift() || null;
        console.log("uniqueNotifications2", state.notificationsQueue);
        if (state.currentNotification?.notification_details.notification_type === "job_notification") {
          state.notificationType = "Job Update";
        }
        else{
          state.notificationType = "Robot Update";
        }
        state.isNotificationOn = true;
      }
    },

    onClick : (state, action: PayloadAction<notificationHistory>) => {
      state.notificationSelected = action.payload;
    },

    resetClick : (state) => {
      state.notificationSelected = null;
    },

    toggleNotification: (state) => {
      state.isNotificationOn = !state.isNotificationOn;
    },
    setNotificationOn: (state) => {
      state.isNotificationOn = true;
    },
    setNotificationOff: (state) => {
      state.notification_id = 0;
      state.isNotificationOn = false;
      state.notificationMessage = '';
      state.notificationTitle = '';
      state.notificationType = '';
    },
    setNotificationType: (state, action: PayloadAction<string>) => {
      state.notificationType = action.payload;
    },
    setNotificationMessage: (state, action: PayloadAction<string>) => {
      state.notificationMessage = action.payload;
    },
    setNotificationTitle: (state, action: PayloadAction<string>) => {
      state.notificationTitle = action.payload;
    },
    setNotificationColorProps: (state, action: PayloadAction<{background: string, border: string}>) => {
        state.colorProps = action.payload
    },
    setNotificationHistoryPanel: (state, action: PayloadAction<boolean>) => {
        state.notificationHistoryPanel = action.payload;
    },
    setNotificationId: (state, action: PayloadAction<number>) => {
        state.notification_id = action.payload;
    }
  },
});

export const {onClick, resetClick, markRobotNotificationAsRead, addNotifications, markNotificationAsRead, setNotificationOff2, showNextNotification, addNotification, setNotificationId, toggleNotification, setNotificationOn, setNotificationOff, setNotificationType, setNotificationMessage, setNotificationTitle, setNotificationColorProps, setNotificationHistoryPanel } = notificationSlice.actions;

export default notificationSlice.reducer;
