import { Box, Button, Fab, IconButton, Slider, Stack, Typography } from "@mui/material";
import mapboxgl, { LngLatLike, Map, Marker } from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import RobotIcon from "../icons/RobotIcon";
import { createRoot } from "react-dom/client";
import {
  calculateRotatedCoordinates,
  calculateScaledCoordinates,
  loadOverlay,
  useRenderGISElementOnMap,
  useRenderLinesOnMap,
  useRenderRobotOnMap,
} from "./helpers/map";
import OverlaySetting from "./models/OverlaySetting";
import "./RobotMap.css";
import { mapboxToken, OVERLAY_APPEND_KEY } from "./constants";
import { Robot } from "./models/Robot";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentFloor, setZoomLevel } from "../../store/map/mapSlice";  // import the action creator
import { NavigateBefore, NorthEastRounded, RotateLeft, RotateRight, SouthWestRounded, VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { useListMap } from "../../hooks/map";
import { bbox, bboxPolygon, center, centroid, points } from "@turf/turf";
import { MapType } from "../../hooks/map.type";

mapboxgl.accessToken = mapboxToken;

export interface RobotMapProps {
  startLocation: LngLatLike;
  zoomLevel: number;
  robots: Robot[];
  children?: React.ReactElement<GIS>[];
  line?: [number, number][];
  onRobotClick?: (robot: Robot) => void;
  renderLocationMarkers?: () => JSX.Element[];
}

export type MapLevelType = { level: number, building: string, name: string }

export interface GIS {
  GIS: LngLatLike;
}

type LoadedMap = {
  overlaySetting: OverlaySetting,
  name: string
}

type SenderRecipientType = {
  id: string,
  location_name: string,
  map: {
    map_name: string,
    map: string,
    building: string,
    robot_map_name: string,
    map_level: number
  }[],
  pose: {
    latitude: number,
    longitude: number,
    z: number,
    theta: number
  },
  store_manager_id: string[]
}

const moreControlButtonStyle = { flex: 'none', minWidth: '36px' }

const RobotMap: React.FC<RobotMapProps> = ({
  startLocation,
  zoomLevel,
  robots,
  children = [],
  line = [],
  onRobotClick,
  renderLocationMarkers
}: RobotMapProps) => {
  const mapContainer = useRef<HTMLElement | string>("");
  const map = useRef<Map | null>(null);
  const dispatch = useDispatch();

  const [selectedMapName, setSelectedMapName] = useState("");
  const [showControlMenu, setShowControlMenu] = useState(false)
  const [loadedMaps, setLoadedMaps] = useState<LoadedMap[]>([])
  const [selectedOpacity, setSelectedOpacity] = useState(100);
  const [lastZoom, setLastZoom] = useState<number>(18);
  const [lastLngLat, setLastLngLat] = useState<mapboxgl.LngLat>();
  const rotateIntervalRef = useRef<NodeJS.Timer | null>(null);
  const opacityIntervalRef = useRef<NodeJS.Timer | null>(null);

  const { data: dataMaps, isLoading: isLoadingMap } = useListMap()

  const onSelectMap = ({ overlaySetting, name }: {
    overlaySetting: OverlaySetting,
    name: string
  }) => {
    setSelectedMapName(name)
    if (!map.current) {
      return
    }
    try {
      // Show Overlay of selected Map (TMP DISABLED)
      // map.current.setPaintProperty(name + OVERLAY_APPEND_KEY, "fill-opacity", 0.15)
      setSelectedOpacity(overlaySetting.opacity)
    } catch (error) { }
  }

  const onDeselect = () => {
    setSelectedMapName('')
    setShowControlMenu(false)
    // TMP DISABLED MAP CONTROL -> hide all overlay fill
    // loadedMaps.map((item) => {
    //   map.current?.setPaintProperty(item.name + OVERLAY_APPEND_KEY, "fill-opacity", 0)
    // })
  }

  // Get Last Coordinate before Open Delivery and restore when closed
  const isDeliveryBoardOpen = useSelector((state: any) => state.deliveryDashboard.isOpen);
  useEffect(() => {
    if(!map.current) return
    if (isDeliveryBoardOpen) {
      setLastZoom(map.current.getZoom())
      setLastLngLat(map.current.getCenter())
      return
    }
    if (!isDeliveryBoardOpen) {
      map.current.flyTo({
        center: lastLngLat,
        zoom: lastZoom
      })
    }
  }, [isDeliveryBoardOpen])

  const updateLayoutPropertyWithOverlay = (name: string, propertyName: string, value: any) => {
    if (!map.current) {
      return
    }
    map.current?.setLayoutProperty(name, propertyName, value);
    // map.current?.setLayoutProperty(name + OVERLAY_APPEND_KEY, propertyName, value);
  }

  const addMap = (overlaySetting: OverlaySetting, name: string) => {
    if (!map.current) {
      return
    }

    // Stop when map name is already exist in array
    const find = loadedMaps.find((item) => item.name === name);
    if (!!find) {
      return
    }
    const tmpMaps = loadedMaps;
    tmpMaps.push({ overlaySetting, name });
    loadOverlay(map, overlaySetting, name);
    setLoadedMaps(tmpMaps)

    // If already more than 1 map, then set default visibility as 'hidden'
    if (tmpMaps.length > 1) {
      updateLayoutPropertyWithOverlay(name, "visibility", "none")
    }
  }

  const filterMapOnly = (data: MapType[]) => {
    if (!data || data.length === 0) {
      return []
    }

    return data.filter((item) => item.robot === '' && item.map_longitude_tl > 0 && item.map_latitude_br > 0)

  }

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (!mapContainer.current) return
    console.log("reinitializing map");
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/hanscau/clxan4269025p01qxaxqc0ekr",
      center: [103.83484878754473, 1.2782008843142307],
      zoom: zoomLevel,
      minZoom: 15,
      maxZoom: 26,
    });

    // Remove Label
    map.current.on("load", () => {
      if (map.current && map.current.isStyleLoaded()) {
        const layers = map.current.getStyle().layers as mapboxgl.Layer[];
        layers.forEach(layer => {
          // remove text label
          if (layer.type === "symbol") {
            map.current?.removeLayer(layer.id);
          }
        });
      }
    })
    
      // get coordinates of the map on click
    map.current?.on('click', (e) => {
        console.log("coord",e.lngLat);
      });
    // });
    // map.current.addControl(new mapboxgl.NavigationControl());
  });

  useEffect(() => {
    if (isLoadingMap || !dataMaps) {
      return
    }

    const { data: listMaps, current_page, num_pages } = dataMaps
    if (!listMaps || listMaps.length === 0) {
      return
    }

    setLoadedMaps([]);
    map.current?.on("load", () => {
      if (map.current && map.current.getStyle()) {
        filterMapOnly(listMaps).map((map) => {
          const mapOverlayData: OverlaySetting = {
            uuid: map.map_uuid,
            url: map.map_image,
            topLeftLng: map.map_longitude_tl,
            topLeftLat: map.map_latitude_tl,
            widthInGIS: Math.abs(map.map_longitude_br - map.map_longitude_tl),
            heightInGIS: Math.abs(map.map_latitude_tl - map.map_latitude_br),
            opacity: 100,
            level: map.map_level
          };
          addMap(mapOverlayData, map.map_name_display.split(" ").join("_"));
        });
      }

      // NOTE Tmp Disable Map Control
      // map.current?.on("click", (e) => {
      //   // Detect if click outside all maps
      //   const feats = map.current?.queryRenderedFeatures(e.point, {
      //     layers: loadedMaps.map((item) => item.name + OVERLAY_APPEND_KEY)
      //   })

      //   if (!feats || feats.length === 0) {
      //     onDeselect()
      //   }
      // })

    })

    // return () => map.current?.remove();
  }, [map.current, isLoadingMap])

  // call useRenderGISElementOnMap on mount


  useRenderGISElementOnMap(map, children);
  useRenderRobotOnMap(map, robots, onRobotClick,);
  useRenderLinesOnMap(map, line);
  // renderLocationMarkers && renderLocationMarkers();


  console.log("rendering map", robots);
  const updateZoomLevel = useSelector((state: any) => state.map.zoomLevel);
  const selectedRobot = useSelector((state: any) => state.robot.selectedRobot);
  const isSelectedRobot = useSelector((state: any) => state.robot.isSelectedRobot);
  const isJobSelected = useSelector((state: any) => state.job.isJobSelected);
  const selectedJob = useSelector((state: any) => state.job.selectedJob);
  const listOfPoints = useSelector((state: any) => state.map.mapDetails);
  const senderLocation = useSelector((state: any) => state.map.senderLocation);
  const recipientLocation = useSelector((state: any) => state.map.recipientLocation);
  // focus on location 
  useEffect(() => {
    if (senderLocation != "" && Array.isArray(listOfPoints)) {
      const sender:SenderRecipientType = listOfPoints.find((point: any) => point.location_name === senderLocation);
      console.log("flying to sender", sender.pose);

      const isDifferentLevel = parseInt(currentFloor) !== sender.map[0].map_level
      if (isDifferentLevel) {
        dispatch(setCurrentFloor(sender.map[0].map_level.toString()))
        // Because not running when change floor level so, duplicate the code
        // TODO: recode the flow of floor level variable
        // Hide Every map first
        loadedMaps.map((item) => {
          updateLayoutPropertyWithOverlay(item.name, "visibility", "none")
        })

        const filter = loadedMaps.filter((item) => item.overlaySetting.level === parseInt(sender.map[0].map_level.toString()));

        filter.map(async (data) => {
          updateLayoutPropertyWithOverlay(data.name, "visibility", "visible")
        })

        const firstFindedMap = filter[0];
        if (!firstFindedMap || !firstFindedMap.overlaySetting.coordinates) {
          return
        }
      }

      map.current?.flyTo({ center: [sender.pose.longitude, sender.pose.latitude], zoom: 20 });
    }
  }, [senderLocation]);

  useEffect(() => {
    if (recipientLocation != "" && Array.isArray(listOfPoints)) {
      const recipient: SenderRecipientType = listOfPoints.find((point: any) => point.location_name === recipientLocation);
      const isDifferentLevel = parseInt(currentFloor) !== recipient.map[0].map_level
      if (isDifferentLevel) {
        dispatch(setCurrentFloor(recipient.map[0].map_level.toString()))
        // Because not running when change floor level so, duplicate the code
        // TODO: recode the flow of floor level variable
        // Hide Every map first
        loadedMaps.map((item) => {
          updateLayoutPropertyWithOverlay(item.name, "visibility", "none")
        })

        const filter = loadedMaps.filter((item) => item.overlaySetting.level === parseInt(recipient.map[0].map_level.toString()));

        filter.map(async (data) => {
          updateLayoutPropertyWithOverlay(data.name, "visibility", "visible")
        })

        const firstFindedMap = filter[0];
        if (!firstFindedMap || !firstFindedMap.overlaySetting.coordinates) {
          return
        }
      }

      map.current?.flyTo({ center: [recipient.pose.longitude, recipient.pose.latitude], zoom: 20 });
    }
  }, [recipientLocation]);

  useEffect(() => {
    console.log("flying to selected robot", selectedRobot.robotStatus?.pose);
    if (isSelectedRobot ) {
      if (map.current) {
          map.current.flyTo({ center: [selectedRobot.robotStatus?.pose.longitude, selectedRobot.robotStatus?.pose.latitude], zoom: 20 });
      }
    }
    else{
      if (map.current) {
          map.current.flyTo({ center: startLocation, zoom: 18 });
      }
    }
  }, [isSelectedRobot]);

  useEffect(() => {
    if (isJobSelected && Array.isArray(listOfPoints)) {
      // get sender location
      console.log("flying to selected job", selectedJob);
      var senderLocation = listOfPoints.find((point: any) => point.location_name === selectedJob.sender_location);
      var recipientLocation = listOfPoints.find((point: any) => point.location_name === selectedJob.recipient_location);
      console.log("flying to selected job 1", senderLocation, recipientLocation);
      if (senderLocation === undefined || recipientLocation === undefined) {
        senderLocation = {
          pose: {
            longitude: 103.8585709834108,
            latitude: 1.282174630782123
          }
        }
        recipientLocation = {
          pose: {
            longitude: 103.8585709834108,
            latitude: 1.282174630782123
          }
        }
      }
      // find midpoint
      const midpoint = {
        longitude: (senderLocation.pose.longitude + recipientLocation.pose.longitude) / 2,
        latitude: (senderLocation.pose.latitude + recipientLocation.pose.latitude) / 2
      }
      console.log("flying to selected job 2", midpoint);
      if (map.current) {
        map.current.flyTo({ center: [midpoint.longitude, midpoint.latitude], zoom: 19 });
      }
    }
    else {
      if (map.current) {
        console.log("flying to selected job back");
        if (isSelectedRobot) {
          map.current.flyTo({ center: [selectedRobot.robotStatus?.pose.longitude, selectedRobot.robotStatus?.pose.latitude], zoom: 20 });
        }
        else {
          map.current.flyTo({ center: startLocation, zoom: 18 });
        }
      }
    }
  }
    , [selectedJob, isJobSelected]);

  useEffect(() => {
    console.log("updateZoomLevel", updateZoomLevel);
    if (map.current) {
      if (map.current.isStyleLoaded()) {
        map.current.flyTo({ zoom: updateZoomLevel });
      }
    }
  }, [updateZoomLevel]);

  useEffect(() => {
    if (map.current) {
      map.current.on('zoomend', () => {
        const newZoomLevel = map.current!.getZoom();
        dispatch(setZoomLevel(newZoomLevel));
        console.log('Current zoom level:', newZoomLevel);
      });
    }
  }, [map.current]);

  const currentFloor = useSelector((state: any) => state.map.currentFloor);
  useEffect(() => {
    if (map.current) {
      // Hide Every map first
      loadedMaps.map((item) => {
        updateLayoutPropertyWithOverlay(item.name, "visibility", "none")
      })

      const filter = loadedMaps.filter((item) => item.overlaySetting.level === parseInt(currentFloor));

      filter.map(async (data) => {
        updateLayoutPropertyWithOverlay(data.name, "visibility", "visible")
      })

      const firstFindedMap = filter[0];
      if (!firstFindedMap || !firstFindedMap.overlaySetting.coordinates) {
        return
      }

      const coordinates = firstFindedMap.overlaySetting.coordinates
      // find bBox of selected Map Coordinates and return it as coordinates
      const bBoxCoords = bboxPolygon(bbox(points(coordinates))).geometry.coordinates.slice(0, 4)[0] as number[][]

      map.current.fitBounds([
        [bBoxCoords[3][0], bBoxCoords[3][1]],
        [bBoxCoords[1][0], bBoxCoords[1][1]]
      ], {
        padding: 20,
        maxZoom: 18
      });
      onDeselect();

      changeRobotVisibility()
    }
  }, [currentFloor]);

  // ONLY WORK IF 1 Level = 1 Map
  const changeRobotVisibility = () => {
    if (isLoadingMap || !dataMaps) {
      return
    }

    // Get Selected Map Data
    const { data: listMaps } = dataMaps
    if (!listMaps || listMaps.length === 0) {
      return
    }
    const findMap = filterMapOnly(listMaps).find((item) => item.map_level === parseInt(currentFloor))
    if (!findMap) {
      return
    }

    // Select All Robot Marker
    const allRobotElems = document.querySelectorAll(".robot-marker");
    if (!allRobotElems || allRobotElems.length === 0) {
      return
    }

    allRobotElems.forEach(element => {
      // Get Robot Id
      const robotIdFromElem = element.getAttribute("robotId");
      
      // Find from robots
      const robot = robots.find((item) => item.robotId === robotIdFromElem);
      if (!robot) {
        return
      }

      // Check if its Map uuid 
      const isSameMapId = (robot.map_uuid && robot.map_uuid == findMap.map_uuid);
      // check if robot layoutId = MapId
      const isMapToLayout = (robot.layout_uuid && robot.layout_uuid == findMap.map_uuid);

      // Set Style (Show/Hide)
      (element as any).style.display = (isSameMapId || isMapToLayout) ? "block" : "none"
    });
  }

  const setCoordinates = (name: string, coordinates: number[][]) => {
    (map.current?.getSource(name) as mapboxgl.ImageSource).setCoordinates(coordinates);
    // TMP DISABLED
    // (map.current?.getSource(name + OVERLAY_APPEND_KEY) as mapboxgl.GeoJSONSource).setData({
    //   type: "Feature",
    //   properties: {},
    //   geometry: {
    //     type: 'Polygon',
    //     coordinates: [coordinates],
    //   },
    // })
  }

  const onRotate = (degree: number) => {
    // If no map selected then stop
    if (!selectedMapName || !map.current) {
      return
    }
    // Find selectedMapName in loadedMaps Array
    const findMaps = loadedMaps.find((item) => item.name === selectedMapName);
    if (!findMaps || !findMaps.overlaySetting || !findMaps.overlaySetting.coordinates) return

    const currentRotation = (findMaps.overlaySetting.rotation ? findMaps.overlaySetting.rotation : degree)
    findMaps.overlaySetting.rotation = currentRotation + degree

    const newCoords = calculateRotatedCoordinates(findMaps.overlaySetting.coordinates, degree);
    findMaps.overlaySetting.coordinates = newCoords;
    setCoordinates(selectedMapName, newCoords)
  }

  const onChangeScale = (scaleFactor: number) => {
    if (!selectedMapName || !map.current) {
      return
    }
    const findMaps = loadedMaps.find((item) => item.name === selectedMapName);
    if (!findMaps || !findMaps.overlaySetting || !findMaps.overlaySetting.coordinates) return

    const newCoords = calculateScaledCoordinates(findMaps.overlaySetting.coordinates, scaleFactor);

    findMaps.overlaySetting.coordinates = newCoords;
    setCoordinates(selectedMapName, newCoords)
  };

  const onRotateLeft = () => {
    onRotate(-1)
  }

  const onRotateRight = () => {
    onRotate(1)
  }

  const rotateLeftHandler = () => {
    rotateIntervalRef.current = setInterval(() => {
      onRotateLeft()
    }, 200);
  }

  const removeRotateLeftHandler = () => {
    if (rotateIntervalRef.current) {
      clearInterval(rotateIntervalRef.current)
      rotateIntervalRef.current = null
    }
  }

  const rotateRightHandler = () => {
    rotateIntervalRef.current = setInterval(() => {
      onRotateRight()
    }, 200);
  }

  const removeRotateRightHandler = () => {
    if (rotateIntervalRef.current) {
      clearInterval(rotateIntervalRef.current)
      rotateIntervalRef.current = null
    }
  }

  const onChangeOpacity = (value: number) => {
    if (!selectedMapName || !map.current || value > 100 || value < 0) {
      return
    }

    const find = loadedMaps.find((item) => item.name === selectedMapName);

    if (!find) { return }
    find.overlaySetting.opacity = value || 0;
    setSelectedOpacity(value)
    map.current.setPaintProperty(selectedMapName, 'raster-opacity', value / 100)

  }

  const increaseOpacityHandler = () => {
    opacityIntervalRef.current = setInterval(() => {
      onChangeOpacity(selectedOpacity + 1)
    }, 200)
  }

  const decreaseOpacityHandler = () => {
    opacityIntervalRef.current = setInterval(() => {
      onChangeOpacity(selectedOpacity - 1)
    }, 200)
  }

  const removeOpacityHandler = () => {
    if (opacityIntervalRef.current) {
      clearInterval(opacityIntervalRef.current)
      opacityIntervalRef.current = null
    }
  }

  const getMapLevels = () => {
    if (!dataMaps || isLoadingMap || !dataMaps.data) {
      return [] as MapLevelType[]
    }

    const results: MapLevelType[] = []
    const notRobotMaps = filterMapOnly(dataMaps.data || []);
    notRobotMaps.map((item) => {
      const find = results.find((result) => result.level === item.map_level)
      if (!find) {
        results.push(({
          level: item.map_level as number || 0,
          building: item.building,
          name: item.map_level < 0 ? "B" + Math.abs(parseInt(item.map_level.toString())) : (item.map_level === 0 ? "GL" : "L" + item.map_level)
        }))
      }
    })

    return results.sort((a, b) => a.level > b.level ? -1 : (b.level > a.level ? 1 : 0))
  }

  return (
    <>
      <Box
        ref={mapContainer}
        sx={{
          width: "100%",
          height: "100%",
          // zIndex: 0,
        }}
      >
      </Box>
      <Stack
        sx={{
          position: "absolute",
          bottom: "120px",
          right: "40px",
          bgcolor: "rgba(187, 187, 187, 0.5)",
          borderRadius: "31px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "62px", bgColor: 'red', margin: "10px auto" }}>
          {getMapLevels()?.map((level, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                color: currentFloor == level.level.toString() ? '#378FFE' : '#9e9e9e',
                textAlign: "center",
                mt: 1,
                mb: 1,
                '&:hover': {
                  cursor: "pointer",
                  color: "#378FFE"
                }
              }}
              onClick={() => dispatch(setCurrentFloor(level.level.toString()))}
            >
              {level.name}
            </Typography>
          ))}
        </Box>
      </Stack>
      {selectedMapName !== '' && (
        <Stack style={{
          position: "absolute",
          bottom: '30px',
          right: '120px',
          height: '62px',
          display: "flex",
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row-reverse',
        }}>
          <Stack>
            <Fab onClick={() => setShowControlMenu(!showControlMenu)} color="primary" aria-label="add">
              <NavigateBefore
                style={{
                  transform: showControlMenu ? "rotate(180deg)" : "",
                  transitionDuration: '300ms',
                  transitionProperty: 'all',
                  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)'
                }}
              />
            </Fab>
          </Stack>
          <Stack style={{
            height: '100%',
            backgroundColor: '#FFF',
            color: '#000',
            display: showControlMenu ? "flex" : "flex",
            opacity: showControlMenu ? 100 : 0,
            transitionDuration: '300ms',
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transform: showControlMenu ? "transform: translateX(0)" : "transform: translateX(0.625rem)",
            // gap: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '6px',
            paddingTop: '6px',
            // flexDirection: 'row',
            borderRadius: '0.5rem', /* 8px */
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
          }}>
            <Typography fontWeight={600} style={{ marginRight: "4px" }}>
              {selectedMapName}
            </Typography>
            <Stack direction={'row'}>
              {
                selectedMapName && loadedMaps.length > 0 && (
                  <>
                    <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
                      <IconButton
                        onClick={() => onChangeOpacity(selectedOpacity - 1)}
                        onMouseDown={decreaseOpacityHandler}
                        onMouseUp={removeOpacityHandler}
                        onMouseLeave={removeOpacityHandler}
                      >
                        <VisibilityOffRounded sx={{ opacity: 0.3 }} />
                      </IconButton>
                      <Slider
                        min={0}
                        max={100}
                        value={selectedOpacity}
                        onChange={(_, value) => onChangeOpacity(value as number)}
                        sx={{ minWidth: '64px' }}
                      />
                      <IconButton
                        onMouseDown={increaseOpacityHandler}
                        onMouseUp={removeOpacityHandler}
                        onMouseLeave={removeOpacityHandler}
                        onClick={() => onChangeOpacity(selectedOpacity + 1)}
                      >
                        <VisibilityRounded />
                      </IconButton>
                    </Stack>
                  </>
                )
              }
              <Button
                onMouseDown={rotateLeftHandler}
                onMouseUp={removeRotateLeftHandler}
                onMouseLeave={removeRotateLeftHandler}
                onClick={onRotateLeft}
                sx={moreControlButtonStyle}
              >
                <RotateLeft />
              </Button>
              <Button
                onMouseDown={rotateRightHandler}
                onMouseUp={removeRotateRightHandler}
                onMouseLeave={removeRotateRightHandler}
                onClick={onRotateRight}
                sx={moreControlButtonStyle}
              >
                <RotateRight />
              </Button>
              <Button
                onClick={() => onChangeScale(0.9)}
                sx={moreControlButtonStyle}
              >
                <SouthWestRounded />
              </Button>
              <Button
                onClick={() => onChangeScale(1.1)}
                sx={moreControlButtonStyle}
              >
                <NorthEastRounded />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default RobotMap;
