import React, { useState, ReactNode, useCallback, useEffect } from 'react';
import TopBar from './Topbar';
import Sidebar from './Sidebar'; // Adjust the import path as necessary
import { useDispatch, useSelector } from 'react-redux';
import { useWebSocket } from '../useWebSocket';
import { setNotificationOn, setNotificationType, setNotificationTitle, setNotificationMessage, setNotificationId, setNotificationOff, addNotification, showNextNotification, markNotificationAsRead, addNotifications, markRobotNotificationAsRead } from '../../store/notification/notificationSlice';
import Notification from '../../routes/dashboard/components/Notification';
import { useRef } from 'react';
import axios from 'axios';
import { JobData, JobDetails2 } from '../../store/types';
import { JobEnums } from '../../utils/enums/JobEnums';
import { useSnackbar } from 'notistack'; 
import { Button } from '@mui/material';
interface MainLayoutProps {
  children: ReactNode;
}
interface JobHistory {
  [order_number: string]: JobDetails2[];
}

interface NotificationDetails {
  id: number;
  title: string;
  description: string;
  created_timestamp: number;
  notification_type: string;
}

interface notificationHistory {
  id: number;
  meta: string;
  notification_details: notificationDetails;
  read: boolean;
  read_timestamp: number;
  type: string;
  user: string;
}

interface notificationDetails {
  created_timestamp: number;
  description: string;
  id : number;
  notification_type: string;
  title: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const profile = localStorage.getItem('profile');
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);
  const audioSrc = require('../../assets/notificationSound.mp3'); // Make sure the path is correct
  const audioRef = useRef<HTMLAudioElement>(null);
  const [orderNumber , setOrderNumber] = useState<string>('');
  const [jobID, setJobID] = useState<number>(0);
  const [jobDetails, setJobDetails] = useState<JobHistory>({});
  const [jobs,setJobs] = useState<JobData>({});
  const [notificationHistory, setNotificationHistory] = useState<notificationHistory[]>([]);
  const [previousJobStatus, setPreviousJobStatus] = useState<{ [order_number: string]: string }>({});
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const parsedProfile = profile ? JSON.parse(profile) : null;
  const isNotificationOn = useSelector((state: any) => state.notification.isNotificationOn);
  const notificationID = useSelector((state: any) => state.notification.notification_id);


  const handleCriticalNotification = (message: any) => {
    dispatch(setNotificationType("Job Update"));
    dispatch(addNotification(message));
  };

  // Utility function to handle non-critical notifications
  const handleNonCriticalNotification = (message: any) => {
    enqueueSnackbar(message.notification_details.description, {
      key: message.id,
      variant: 'info',
      action: (key) => (
        <Button onClick={() => handleCloseSnackbar(key, message.id)}>Dismiss</Button>
      ),
      persist: false,
      style: { backgroundColor: '#EDFAFF', color: '#000', fontFamily: "Poppins" },
      autoHideDuration: 5000, 
      onClose: (event, reason, key) => {
        // Only call handleCloseSnackbar if the reason is 'timeout' or 'clickaway'
        if (reason === 'timeout') {
          console.log("Dismissed due to timeout", key);
          handleCloseSnackbar(key, message.id);
        }
      },
    });
  };

  const handleCloseSnackbar = (key: any, message_id: number) => {
    closeSnackbar(key);
    sendNotificationReadMessage({
      type: "receive_read",
      notification_id: message_id,
    });
  }

  const handleNotification = useCallback((message: any) => {
    console.log("Notification received:", message);

    const isUserMessage = parsedProfile && parsedProfile?.uuid === message.user;

    if (message.notification_details.notification_type === "job_notification") {
      // Differentiate between sender and recipient notifications
      const orderNumber = JSON.parse(message.meta).order_number;
      console.log("check message id", message.id);
      if (message.read) {
        // remove notification from queue
        console.log("Marking notification as read", orderNumber, message);
        dispatch(markNotificationAsRead(orderNumber));
      } else {
        // job is not read, handle
        if (message.type === "sender_notification" && isUserMessage) {
          if (message.notification_details.title === "Robot Arrived at pickup point") {
            console.log("Critical notification sender", message.notification_details.description);
            handleCriticalNotification(message);
          } else {
            console.log("Non-critical notification sender ", message.notification_details.title);
            handleNonCriticalNotification(message);
          }
        } else if (message.type === "recipient_notification" && isUserMessage) {
          if (message.notification_details.title === "Robot Arrived at delivery point" ) {
            handleCriticalNotification(message);
          } else{
            console.log("Non-critical notification recipient", message.notification_details.title);
            handleNonCriticalNotification(message);
          }
        }
      }
    } else if (message.notification_details.notification_type === "robot_notification") {
      const robot_id = JSON.parse(message.meta).robot_id;
      if (message.read) {
        dispatch(markRobotNotificationAsRead(robot_id));
      }else{
        dispatch(setNotificationType("Robot Update"));
        dispatch(addNotification(message));
      }
    } else {
      const orderNumber = JSON.parse(message.meta).order_number;
      if (message.read) {
        dispatch(markNotificationAsRead(orderNumber));
      } else {
        dispatch(setNotificationType("Failed"));
        dispatch(addNotification(message));
      }
    }
  }, []);

  const queryNotificationHistory = async (pageToLoad = 1, readStatus = false) => {
    console.log("Querying notification history", pageToLoad);
    try {
        const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/notification/?page=${pageToLoad}&page_size=20&read=${readStatus}`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });

        if (response.status === 200) {

            const newNotifications = response.data.data;
            setNotificationHistory((prev) => [...prev, ...newNotifications]);
      
            // Get the existing notification IDs from the state
            const existingNotificationIds = new Set(notificationQueue.map((n: notificationHistory) => n.id));
          
            // Filter out duplicates
            const uniqueNotifications = newNotifications.filter(
              (notification: notificationHistory) => !existingNotificationIds.has(notification.id)
            );
            console.log("Unique notifications", response.data.data);

            // Keep only critical notifications
            const criticalNotifications = uniqueNotifications.filter(
              (notification: notificationHistory) => 
                (notification.notification_details.title === "Robot Arrived at pickup point" && notification.type === "sender_notification") ||
                (notification.notification_details.title === "Robot Arrived at delivery point" && notification.type === "recipient_notification") ||
                notification.notification_details.notification_type === "robot_notification"
            );
            console.log("Critical notifications", criticalNotifications);
            
            const nonCriticalNotifications = uniqueNotifications.filter(
              (notification: notificationHistory) => (
                (notification.notification_details.title !== "Robot Arrived at pickup point" || notification.type !== "sender_notification") &&
                (notification.notification_details.title !== "Robot Arrived at delivery point" || notification.type !== "recipient_notification") &&
                notification.notification_details.notification_type !== "robot_notification")
            );
            
            
            console.log("Non-critical notifications", nonCriticalNotifications);
            // Mark non-critical notifications as read immediately
            nonCriticalNotifications.forEach((notification: notificationHistory) => {
              console.log("Marking non-critical notification as read:", notification.id);
              sendNotificationReadMessage({
                type: "receive_read",
                notification_id: notification.id,
              });
            });

            console.log("Adding unique notifications:", criticalNotifications);
      
            // Add all unique notifications at once
            if (criticalNotifications.length > 0) {
              dispatch(addNotifications(criticalNotifications));
            }
          console.log("Notification history", newNotifications);
        }
    } catch (error) {
        console.error(error);
    }
};
const notificationQueue = useSelector((state: any) => state.notification.notificationsQueue);

const [counter, setCounter] = useState(0);
const [queried, setQueried] = useState(false);
  useEffect(() => {
    if (!queried) {
      setQueried(true);
      queryNotificationHistory();
    }
  }, [queried]);



  useEffect(() => {
    console.log("Job details", jobDetails)
    console.log("Jobs", jobs)
  }, [jobs, jobDetails])

  // useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/notification/${parsedProfile.uuid}/`, handleNotification);
  const notificationWebSocketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/notification/${parsedProfile?.uuid}/`;
  const sendNotificationReadMessage = useWebSocket(notificationWebSocketUrl, handleNotification);
  const currentNotification = useSelector((state: any) => state.notification.currentNotification);  

  const handleMessage = useCallback((data: any) => {
    const job = data;
    console.log("Job data received:", job);
  
    setJobs((prevJobs) => {
      const updatedJobs = { ...prevJobs, [job.order_number]: job };
  
      // Retrieve the previous status from state
      const previousStatus = previousJobStatus[job.order_number];
      console.log("previousStatus:", previousStatus);
      console.log("job.job_status:", job.job_status);
  
      // If there's a status change or if it's the first time we're seeing this job, update previousJobStatus
      if (!previousStatus || previousStatus !== job.job_status) {
        console.log(`Updating job status from ${previousStatus} to ${job.job_status}`);
  
        // Update the previous job status
        setPreviousJobStatus((prevStatus) => ({
          ...prevStatus,
          [job.order_number]: job.job_status,
        }));
  
        // Check if the new job status means the notification should be marked as read
        if (job.job_status === JobEnums.ITEM_LOADED || job.job_status === JobEnums.ITEM_DELIVERED) {
          console.log(`Marking notification for order ${job.order_number} as read due to status change. notificationQueue:`, notificationQueue);
  
          // Find the notification ID in the queue to send read confirmation
          const notification = notificationQueue.find((notification: any) => 
            JSON.parse(notification.meta).order_number === job.order_number
          );

          console.log("Notification to mark as read:", notification);

          dispatch(markNotificationAsRead(job.order_number));
          if (currentNotification && JSON.parse(currentNotification.meta).order_number === job.order_number) {
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: currentNotification.id,
            });
          }
          if (notification) {
            const id = notification.id;
            // Send read confirmation
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: id,
            });
          }
        }
      }
  
      return updatedJobs;
    });
  }, [dispatch, previousJobStatus, notificationQueue, sendNotificationReadMessage]);

  const jobOverviewUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${parsedProfile?.uuid}/`;
  useWebSocket(jobOverviewUrl, handleMessage);
  
  // useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/notification/${parsedProfile.uuid}/`, handleNotification);
  const closeNotification = ( id: number ) => {

    dispatch(setNotificationOff());

    // After 1 second, show the next notification if available
    setTimeout(() => {
        dispatch(showNextNotification());
    }, 1000);
    sendNotificationReadMessage({
      type: "receive_read",
      notification_id: id,
    });
    //set current notification to now
  };
  return (
    <div>
      <TopBar toggleSidebar={toggleSidebar} handleNotificationDisplay={() => {}} notificationCount={0} handleIsOpen={toggleSidebar} />
      {/* <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} /> */}
      <main>{children}</main>

      { isNotificationOn ? 
          <>
              <Notification onClose={closeNotification} />
              <audio ref={audioRef} src={audioSrc} autoPlay />
          </>
          : null
      }
      {/* { isNotificationOn &&
        <NoncriticalNotification/>  
    } */}
    </div>
  );
};

export default MainLayout;
