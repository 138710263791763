import React, { useEffect, useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Typography, Box, Tabs, Tab, CircularProgress, Skeleton } from '@mui/material';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch } from 'react-redux';
import { onClick, setNotificationHistoryPanel } from '../../../store/notification/notificationSlice';
import axios from 'axios';
import swiftbot from '../../../assets/swiftbot.png'; 
import notification from '../../../assets/notification.png'; 
import InfiniteScroll from 'react-infinite-scroll-component';

interface NotificationHistoryPanelProps {
    open: boolean;
    dummyNotificationHistory: JobData;
    handleSelectJob: (job: JobDetails2) => void;
    handleDeleteNotification: (order_number: string) => void;
    // handleNotificationSelection: (value: string, type: string) => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
    recurring_type: string;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

interface notificationHistory {
    id: number;
    meta: string;
    notification_details: notificationDetails;
    read: boolean;
    read_timestamp: number;
    type: string;
    user: string;
}

interface notificationDetails {
    created_timestamp: number;
    description: string;
    id: number;
    notification_type: string;
    title: string;
}

const NotificationHistoryPanel: React.FC<NotificationHistoryPanelProps> = ({ open}) => {
    const dispatch = useDispatch();
    const [notificationHistory, setNotificationHistory] = useState<notificationHistory[]>([]);
    const [tabValue, setTabValue] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState<number>(0);
    const [isRead, setIsRead] = useState(true);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        const readStatus = newValue === 0;
        setIsRead(readStatus);
        resetAndFetch(readStatus);
    };

    const resetAndFetch = (readStatus: boolean) => {
        setNotificationHistory([]);
        setPage(1);
        setHasMore(true);
        queryNotificationHistory(1, readStatus);
    };

    const queryNotificationHistory = async (pageToLoad = 1, readStatus = true) => {
        console.log("queryNotificationHistory", pageToLoad, readStatus);
        if (pageToLoad > totalPages) {
            setHasMore(false);
            return;
        }

        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/notification/?page=${pageToLoad}&page_size=20&${readStatus ? "" : "read=false"}`,
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });

            if (response.status === 200) {
                const newNotifications = response.data.data;
                setNotificationHistory((prev) => [...prev, ...newNotifications]);
                setTotalPages(response.data.num_pages);
                setPage(pageToLoad + 1);
                setCount(response.data.count);
                setHasMore(pageToLoad < response.data.num_pages);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (open) {
            // Reset and fetch data from the start when the panel is opened
            resetAndFetch(isRead);
        }
    }, [open]);

    const fetchMoreData = () => {
        if (hasMore) {
            queryNotificationHistory(page, isRead);
        }
    };

    const handleNotificationClick = (notification: notificationHistory) => {
        const meta = JSON.parse(notification.meta);
        dispatch (onClick(notification));
    }


    return (
        <Drawer anchor="right" open={open} onClose={() => dispatch(setNotificationHistoryPanel(false))}>
            <Box sx={{ width: 350, height: "100vh", overflow: "hidden" }}>
                <Box sx={{ display: "flex", flexDirection: "row", margin: "auto", mt: 2, ml: 1 }}>
                    <IconButton onClick={() => dispatch(setNotificationHistoryPanel(false))} sx={{ mt: -0.8 }}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h5" sx={{ fontSize: "24px", ml: 2 }}>
                        Notifications
                    </Typography>
                </Box>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                        mt: 2,
                        ml: 2,
                        fontSize: "8px",
                        '.MuiTabs-indicator': {
                            display: 'none',  
                        },
                        '& .MuiTab-root': {
                            color: '#A0A0A0', 
                            fontSize: '12px',
                            height: '20px', 
                            minHeight: '20px',
                            '&.Mui-selected': {
                                color: '#087D46', 
                                backgroundColor: '#00E58E',
                                borderRadius: '30px',
                            }
                        }
                    }}
                >
                    <Tab label="All" />
                    <Tab label="Unread" />
                </Tabs>
                {notificationHistory.length === 0 ? (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 10 }}>
                        {/* <img src={notification} alt="notification" style={{ height: "160px", margin: "auto" }} /> */}
                        <Typography variant="h5" sx={{ mt: 2, fontSize: "20px" }}>
                            No unread notification
                        </Typography>
                    </Box>
                ) : (
                    <Box id="scrollableDivNoti" sx={{ height: "calc(100vh - 160px)", overflow: "auto" }}>
                        <InfiniteScroll
                            dataLength={notificationHistory.length}
                            next={fetchMoreData}
                            height={"calc(100vh - 160px)"}
                            hasMore={hasMore}
                            loader={
                                <Box sx={{ padding: 2 }}>
                                    <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                                    <Skeleton variant="rectangular" height={50} />
                                </Box>
                            }
                            endMessage={
                                <Typography sx={{ textAlign: 'center', mt: 2 }}>
                                   No more notifications
                                </Typography>
                            }
                            scrollableTarget="scrollableDivNoti"
                        >
                            <List sx={{mt:-1}}>
                                {notificationHistory.map((job, index) => (
                                    <Box key={index}>
                                        <ListItem sx={{ py: 1, '&:hover': { backgroundColor: '#f4f4f4', cursor: 'pointer' }, paddingLeft: "20px" }} onClick={() => handleNotificationClick(job)}>
                                            <ListItemIcon>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#87c3ff", borderRadius: "50%", width: "50px", height: "50px", justifyContent: "center" }}>
                                                    <img src={swiftbot} style={{ width: "30px", height: "30px" }} />
                                                </Box>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right", ml: 2 }}>
                                                    <Typography sx={{ color: "black", fontSize: "12px" }}>
                                                        {job.notification_details.description}
                                                    </Typography>
                                                    <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                                                        {new Date(job.notification_details.created_timestamp * 1000).toLocaleDateString()}, {new Date(job.notification_details.created_timestamp * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                    </Typography>
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                        {index < notificationHistory.length - 1 && <Divider sx={{ width: "90%", margin: "auto" }} />}
                                    </Box>
                                ))}
                            </List>
                        </InfiniteScroll>
                    </Box>
                )}
            </Box>
        </Drawer>
    );
}

export default NotificationHistoryPanel;
