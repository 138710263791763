import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
interface FeatureTabProps {
    actions?: string[];
    columns: Column[];
    items: FeatureGrouping[];
}

interface Column {
    id: string;
    label: string;
    padding?: string;
}   

interface FeatureData {
    RobotMonitoring: {
        ViewRobotLocationsOnMap: boolean;
        ViewRobotStatuses: boolean;
        ViewNotificationAlerts: boolean;
        ViewCriticalAlerts: boolean;
    };
    JobManagement: {
        CreateNewJob: boolean;
        ViewAllJob: boolean;
        CreateScheduledJob: boolean;
        CancelScheduledJob: boolean;
        ViewJobsCreatedbyUser: boolean;
        UpdateJobsOwn: boolean;
        UpdateJobsAll: boolean;
        CancelJobsOwn: boolean;
        CancelJobsAll: boolean;
    };
    NotificationManagement: {
        ReceiveGeneralJobNotificationSelf: boolean;
        ReceiveNotificationAtDeliveryLocationAsRecipient: boolean;
        ReceiveNotificationAtPickUpLocationAsSender: boolean;
        ReceiveCriticalSystemNotifications: boolean;
        ManageNotificationPreferences: boolean;
    };
    ReportLogs: {
        GenerateAllJobReports: boolean;
        ViewRobotLogs: boolean;
    };
    UserManagement: {
        ViewAllUsers: boolean;
        AddOrRemoveUsers: boolean;
        AssignRoles: boolean;
    };
    UserProfileManagement: {
        ConfigureUserProfileSelf: boolean;
        AssignUserProfileLocation: boolean;
    };
    MapManagement: {
        ConfigureRobotMapLayout: boolean;
        AddOrRemoveMarkersOnMap: boolean;
        ManageMapLayoutAndRobotMap: boolean;
        AddOrRemoveRobotsFromMap: boolean;
    };
    RobotManagement: {
        ViewRobotInformation: boolean;
        AddOrRemoveRobots: boolean;
        UpdateRobotInformation: boolean;
    };
}
interface FeatureGrouping {
    name: string;
    features: FeatureData;
}

const FeatureTab: React.FC<FeatureTabProps> = ({ items, columns, actions }) => {
    
    const renderFeatureValue = (features: FeatureData, featureKey: string): React.ReactNode => {
        const featureGroup = features[featureKey as keyof FeatureData];
        const allEnabled = Object.values(featureGroup).every(value => value);
        return (
            <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
                {allEnabled ? 'Full Access' : 'Partial Access'}
            </Typography>
        );
    };
    
    const navigate = useNavigate();
    return ( 
        <Box>
            <TableContainer component={Paper} sx={{ overflow: "auto", maxHeight: "680px" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center" sx={{ padding: column.padding, backgroundColor: "#f2f2f2" }}>
                                    <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        {column.label}
                                    </Typography>
                                </TableCell>
                            ))}
                            {actions && (
                                <TableCell align="center" sx={{ backgroundColor: "#f2f2f2" }}>
                                    <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Actions</Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="center" sx={{ padding: "15px", fontSize: "13px" }}>{item.name}</TableCell>
                                {columns.slice(1).map((column) => (
                                    <TableCell key={column.id} align="center" sx={{ padding: "15px", fontSize: "13px" }}>
                                        {renderFeatureValue(item.features, column.id)}
                                    </TableCell>
                                ))}
                                {actions && (
                                    <TableCell align="center" sx={{ padding: "15px" }}>
                                        <Button variant="contained" color="primary" size="small">
                                            Edit
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => navigate("/access-control/new-grouping")}>
                    Add Grouping
                </Button>
            </Box>
        </Box>
    );
}

export default FeatureTab;
