import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Fuse from "fuse.js";
import JobTable from './Components/JobTable';
import { Menu, IconButton, InputLabel, Box, Typography, Button, Grid, TextField, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Alert, Chip, CircularProgress, DialogContentText, Select, MenuItem, FormControl, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { JobDetails3, JobData2 } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalJobNumber, setTotalPageNumber, setCurrentPageNumber } from "../../store/page/jobPageSlice";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import csv from '../../assets/csv.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { JobEnums } from '../../utils/enums/JobEnums';
interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation_name: string;
    organisation: string;
}

interface columnType {
    id: string;
    label: string;
    padding?: string;
}

interface Duration {
    start_time: number;
    end_time: number;
}

const JobHistory: React.FC = () => {
    const [jobs, setJobs] = useState<JobData2>({});
    const [filteredJobs, setFilteredJobs] = useState<JobData2>({});
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const pageSize = useSelector((state: any) => state.jobPage.jobsPerPage);
    const pageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);
    const [reportId, setReportId] = useState<number>(0);
    const [duration, setDuration] = useState<Duration>({ start_time: 0, end_time: 0 });
    const [statusFilter, setStatusFilter] = useState<string>("all");
    const [startLocationFilter, setStartLocationFilter] = useState<string>("All");
    const [endLocationFilter, setEndLocationFilter] = useState<string>("All");
    const [allStartLocations, setAllStartLocations] = useState<string[]>([]);
    const [allEndLocations, setAllEndLocations] = useState<string[]>([]);
    const [robotFilter, setRobotFilter] = useState<string>("All");
    const [appliedFilters, setAppliedFilters] = useState<{ label: string; value: string; key: string }[]>([]);
    const [isExporting, setIsExporting] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [exportType, setExportType] = useState<string>("simplified");
    const [robots, setRobots] = useState<{ [robot_mac: string]: { generalInfo: GeneralRobotInfo } }>({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        getJobList(newPage + 1, rowsPerPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageSize = parseInt(event.target.value, 10);
        setRowsPerPage(pageSize);
        setPage(0);
        getJobList(1, pageSize);
      };
    
    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectExportType = (type: string) => {
        setExportType(type);
        handleCloseMenu();
        createReport(type);  // Call the createReport function with the selected type
    };

    const jobsColumn: columnType[] = [
        { id: 'order_number', label: 'Job No.', padding: "15px 20px" },
        { id: 'job_type', label: 'Job Type', padding: "15px 20px" },
        { id: 'robot_name', label: 'Robot Name', padding: "15px 20px" },
        { id: 'load_compartment', label: 'Compartment', padding: "15px 20px" },
        { id: 'sender_location', label: 'From' },
        { id: 'recipient_location', label: 'To' },
        { id: 'status', label: 'Status' },
        { id: 'duration', label: 'Duration' },
        { id: 'created_at', label: 'Created Time' },
        { id: 'timestamp', label: 'Completed Time' },
    ];

    const createReport = async (reportType: string) => {
        const startTime = duration.start_time === 0 ? "" : duration.start_time;
        const endTime = duration.end_time === 0 ? "" : duration.end_time;
        console.log("duration", duration, statusFilter, startLocationFilter, endLocationFilter, startTime, endTime, reportType);
        await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/generate_report/`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            data: {
                start_time: startTime,
                end_time: endTime,
                status: statusFilter === "all" ? "" : statusFilter,
                start_location: startLocationFilter === "All" ? "" : startLocationFilter,
                end_location: endLocationFilter === "All" ? "" : endLocationFilter,
                report_type: reportType,
                robot_name: robotFilter === "All" ? "" : robotFilter,
            }
        }).then((response) => {
            if (response.status === 200) {
                setReportId(response.data.report_id);
                setIsExporting(true);
                setTimeout(() => {
                    setIsExporting(false);
                    navigate(`/reports`);
                }, 5000);
            }
        }).catch((error) => {
            console.error(error);
        });
    }
    const getJobList = async (pageNumber: number, pageSize: number) => {
        console.log("duration", duration, statusFilter, startLocationFilter, endLocationFilter);
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/`,
            // url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_list/?page=${pageNumber}&page_size=${pageSize}&start_time=${duration.start_time === 0 ? "" : duration.start_time}&end_time=${duration.end_time === 0 ? "" : duration.end_time}&status=${statusFilter === "all" ? "" : statusFilter}&start_location=${startLocationFilter === "All" ? "" : startLocationFilter}&end_location=${endLocationFilter === "All" ? "" : endLocationFilter}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            params: {
                page: pageNumber,
                page_size: pageSize,
                status: statusFilter === "all" ? "" : statusFilter,
                start_time: duration.start_time === 0 ? "" : duration.start_time,
                end_time: duration.end_time === 0 ? "" : duration.end_time,
                start_location: startLocationFilter === "All" ? "" : startLocationFilter,
                end_location: endLocationFilter === "All" ? "" : endLocationFilter,
                robot_name: robotFilter === "All" ? "" : robotFilter,
            },
        }).then((response) => {
            if (response.status === 200) {
                dispatch(setTotalJobNumber(response.data.count));
                dispatch(setTotalPageNumber(response.data.num_pages));
                dispatch(setCurrentPageNumber(response.data.current_page));
                setJobs(response.data.data.reduce((acc: JobData2, job: JobDetails3) => {
                    acc[job.job_id] = job;
                    return acc;
                }, {}));
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    const queryRobots = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot/`, {
                headers: { "Authorization": `Bearer ${localStorage.getItem('accessToken')}` },
            });
            const robotsData = await Promise.all(data.data.map(async (robot: GeneralRobotInfo) => {
                console.log("Robot:", robot);
                // const organisationName = await getOrganisation(robot.organisation);
                return {
                    ...robot,
                    organisation_name: "Autove",
                };
            }));
            console.log("Robots data:", robotsData);    
            const robotsMap = robotsData.reduce((acc, robot) => ({
                ...acc,
                [robot.robot_mac]: {
                    generalInfo: {
                        robot_name: robot.robot_name,
                        robot_mac: robot.robot_mac,
                        robot_serial_number: robot.robot_serial_number,
                        robot_type: robot.robot_type,
                        organisation: robot.organisation_name,
                    },
                },
            }), {});
            console.log("Robots map:", robotsMap);
            setRobots(robotsMap);
        } catch (error) {
            console.error("Failed to fetch robots:", error);
        }
    };

    const queryMapLocations = async () => {
        await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/location/`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                console.log("response", response.data);
                // setListOfLocations(response.data);
                // store location_name
                const startLocations = response.data.data.map((location: any) => location.location_name);
                setAllStartLocations(startLocations);
                setAllEndLocations(startLocations);
            }
        }
        ).catch((error) => {
            console.error(error);
        });
    }
    const fuseOptions = {
        keys: ["job_id"],
        includeScore: true
    };

    const fuse = new Fuse(Object.values(jobs), fuseOptions);

    useEffect(() => {
        queryMapLocations();
        queryRobots();
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const results = fuse.search(searchTerm).map(result => result.item);
            setFilteredJobs(Object.fromEntries(results.map(job => [job.job_id, job])));
        } else {
            setFilteredJobs(jobs);
        }
    }, [searchTerm, jobs]);

    const [applySubmitted, setApplySubmitted] = useState(false);

    const handleApplyFilters = () => {
        const newFilters = [];
        if (statusFilter !== "all") {
            newFilters.push({ label: `Status: ${statusFilter}`, value: statusFilter, key: 'statusFilter' });
        }
        if (startLocationFilter !== "All") {
            newFilters.push({ label: `Start Location: ${startLocationFilter}`, value: startLocationFilter, key: 'startLocationFilter' });
        }
        if (endLocationFilter !== "All") {
            newFilters.push({ label: `End Location: ${endLocationFilter}`, value: endLocationFilter, key: 'endLocationFilter' });
        }
        if (robotFilter !== "All") {
            newFilters.push({ label: `Robot Name: ${robotFilter}`, value: robotFilter, key: 'robotFilter' });
        }
        if (startDate || endDate) {
            const formattedStartDate = startDate ? startDate.format('DD/MM/YYYY') : '';
            const formattedEndDate = endDate ? endDate.format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY');
            newFilters.push({ label: `Date Range: ${formattedStartDate} - ${formattedEndDate}`, value: "", key: 'dateRange' });
            if (startDate) {
                const end = endDate || dayjs();
                setDuration((prev) => ({ ...prev, start_time: startDate.unix(), end_time: end.endOf('day').unix() }));
            }
        }
        setAppliedFilters(newFilters);
        setApplySubmitted(true);
        setFilterDialogOpen(false);
        setPage(0);
    };

    

    const handleDeleteChip = (key: string) => {
        setAppliedFilters((prevFilters) => prevFilters.filter(filter => filter.key !== key));
        
        // Reset the filter state values based on the deleted chip key
        if (key === 'statusFilter') {
            setStatusFilter("all");
        } 
        if (key === 'startLocationFilter') {
            setStartLocationFilter("All");
        }
        if (key === 'endLocationFilter') {
            setEndLocationFilter("All");
        }
        if (key === 'dateRange') {
            setStartDate(null);
            setEndDate(null);
            setDuration({ start_time: 0, end_time: 0 });
        }

        if (key === 'robotFilter') {
            setRobotFilter("All");
        }
    };
    

    const handleStartDateChange = (newStartDate: Dayjs | null) => {
        const today = dayjs();
        if (!newStartDate || !dayjs.isDayjs(newStartDate) || newStartDate.isAfter(today)) {
            setStartDate(null);
            setDuration((prev) => ({ ...prev, start_time: 0 }));
            return;
        }
        const startOfDay = dayjs(newStartDate).startOf('day');
        setStartDate(startOfDay);
        setDuration((prev) => ({ ...prev, start_time: startOfDay.unix() }));
    };

    const handleEndDateChange = (newEndDate: Dayjs | null) => {
        const today = dayjs();
        if (!newEndDate || !dayjs.isDayjs(newEndDate) || newEndDate.isAfter(today)) {
            setEndDate(null);
            setDuration((prev) => ({ ...prev, end_time: 0 }));
            return;
        }
        const endOfDay = dayjs(newEndDate).endOf('day');
        setEndDate(endOfDay);
        setDuration((prev) => ({ ...prev, end_time: endOfDay.unix() }));
    };

    useEffect(() => {
        console.log("Fetching job list with filters:", duration, statusFilter, startLocationFilter, endLocationFilter);
        dispatch (setCurrentPageNumber(1));
        getJobList(1, pageSize);
    }, [appliedFilters]);
    

    // useEffect(() => {
    //     getJobList(pageNumber, pageSize);
    // }, [pageNumber, pageSize]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <Box sx={{ maxWidth: "95%", m: "15px auto", height: "100%", }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px auto" }}>
                    <TextField
                        id="search-store-name"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            width: "94%",
                            background: "white",
                            "& .MuiOutlinedInput-root": {
                                height: "40px",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => setFilterDialogOpen(true)}
                        sx={{ marginLeft: 2, background: "#fff", border: "1px solid #c0c0c0", boxShadow: "none", '&:hover': { background: "#f5f5f5" } }}
                    >
                        <FilterAltIcon sx={{ color: "#c0c0c0" }} />
                    </Button>
                    {/* <Button
                        variant="outlined"
                        sx={{ marginLeft: 2 }}
                        onClick={() => createReport()}
                    >
                        Export
                    </Button> */}

                    <Button
                        variant="outlined"
                        sx={{ marginLeft: 2 }}
                        onClick={handleExportClick}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        Export
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => handleSelectExportType("simplified")}>Simplified</MenuItem>
                        <Divider sx={{ my: 1 , width:"95%", m:"auto"}} />
                        <MenuItem onClick={() => handleSelectExportType("detailed")}>Detailed</MenuItem>
                    </Menu>
                </Box>

                {/* Display applied filter chips */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                    {appliedFilters.map((filter) => (
                        <Chip
                            key={filter.key}
                            label={
                                //uppercase the first letter of the filter label
                                filter.label.charAt(0).toUpperCase() + filter.label.slice(1)
                            }
                            onDelete={() => handleDeleteChip(filter.key)}
                            sx={{ backgroundColor: "#F1F7FD", color: "#378FFE", border: "1px solid #CEDBFF" }}
                        />
                    ))}
                </Box>

                {/* Filter Dialog */}
                <Dialog open={filterDialogOpen} onClose={() => setFilterDialogOpen(false)} fullWidth maxWidth="sm">
                    <DialogTitle>
                        Filter By
                        <IconButton
                            aria-label="close"
                            onClick={() => setFilterDialogOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: "#a5a5a5"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <FormControl fullWidth sx={{ mb: 2, mt:1 }}>
                            <InputLabel id="robot-id-label">Robot Name</InputLabel>
                            <Select
                                labelId="start-location-label"
                                id="robot_id"
                                name="robot_id"
                                label="Robot Name"
                                value={robotFilter}
                                onChange={(e) => setRobotFilter(e.target.value)}
                            >
                                <MenuItem value="All">All Robots</MenuItem>
                                {Object.values(robots).map((robot, index) => (
                                    <MenuItem key={index} value={robot.generalInfo.robot_name}>
                                        {robot.generalInfo.robot_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                name="status"
                                label="Status"
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="all">All Status</MenuItem>
                                {/* <MenuItem value={JobEnums.NOT_ASSIGNED}>Not Assigned</MenuItem>
                                <MenuItem value={JobEnums.ASSIGNED}>Assigned</MenuItem>
                                <MenuItem value={JobEnums.ASSIGNING_COMPARTMENT}>Assigning Compartment</MenuItem>
                                <MenuItem value={JobEnums.PICKING_UP}>Picking Up</MenuItem>
                                <MenuItem value={JobEnums.ARRIVED_AT_PICKUP}>Arrived At Pickup</MenuItem>
                                <MenuItem value={JobEnums.ITEM_LOADED}>Item Loaded</MenuItem>
                                <MenuItem value={JobEnums.DELIVERING}>Delivering</MenuItem>
                                <MenuItem value={JobEnums.ARRIVED_AT_DELIVERING}>Arrived At Delivering</MenuItem>
                                <MenuItem value={JobEnums.ITEM_DELIVERED}>Item Delivered</MenuItem>
                                <MenuItem value={JobEnums.RETURN_TO_SENDER}>Return To Sender</MenuItem>
                                <MenuItem value={JobEnums.PICKED_UP_FAILED}>Picked Up Failed</MenuItem>
                                <MenuItem value={JobEnums.DELIVERY_FAILED}>Delivery Failed</MenuItem>
                                <MenuItem value={JobEnums.RETURN_FAILED}>Return Failed</MenuItem>
                                <MenuItem value={JobEnums.CANCELLED}>Cancelled</MenuItem> */}
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="in_progress">In Progress</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="failed">Failed</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="start-location-label">Start Location</InputLabel>
                            <Select
                                labelId="start-location-label"
                                id="startLocation"
                                name="startLocation"
                                label="Start Location"
                                value={startLocationFilter}
                                onChange={(e) => setStartLocationFilter(e.target.value)}
                            >
                                <MenuItem value="All">All Start Locations</MenuItem>
                                {allStartLocations.map((location, index) => (
                                    <MenuItem key={index} value={location}>
                                        {location}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="end-location-label">End Location</InputLabel>
                            <Select
                                labelId="end-location-label"
                                id="endLocation"
                                name="endLocation"
                                label="End Location"
                                value={endLocationFilter}
                                onChange={(e) => setEndLocationFilter(e.target.value)}
                            >
                                <MenuItem value="All">All End Locations</MenuItem>
                                {allEndLocations.map((location, index) => (
                                    <MenuItem key={index} value={location}>
                                        {location}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography sx={{ mb: 2 }}>Select Date Range:</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={5.5}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                />
                            </Grid>
                            <Grid item xs={0.5}>
                                <Typography sx={{ mt: 2 }}>-</Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    minDate={startDate || dayjs()}
                                    maxDate={dayjs()}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleApplyFilters} sx={{ display: 'block', width: '100%' }}>
                            Apply Filters
                        </Button>
                    </DialogActions>
                </Dialog>
                <JobTable columns={jobsColumn} items={filteredJobs} getJobList={getJobList} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                <Dialog open={isExporting} onClose={() => setIsExporting(false)}>
                    <DialogTitle>Report Generation</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex", flexDirection: "column", alignText: "justify" }} mb={2}>
                            <DialogContentText>
                                Your file is being generated. Redirecting to the download page in a few seconds...
                            </DialogContentText>
                            <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="flex-start" mt={4} sx={{ width: "100%", mb: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start" }}>
                                    <img src={csv} alt="csv" style={{ height: "50px" }} />
                                    <Typography component="div" sx={{ ml: 3, fontSize: "16px", mt: 1.5 }}>
                                        Report Reference ID: {reportId}
                                    </Typography>
                                </Box>
                                <CircularProgress sx={{ ml: 2 }} />
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Box>
        </LocalizationProvider>
    );
};

export default JobHistory;
